"use client";

import { Spinner } from "@heroui/react";
import React from "react";

const loading = () => {
  return (
    <div className="h-[500px] w-full flex flex-col  justify-center items-center">
      <div>
        <Spinner size="lg" color="success" />
      </div>
      <div className="text-2xl top-[30%] md:text-6xl lg:text-3xl  p-4 bg-clip-text bg-no-repeat  text-transparent bg-gradient-to-r from-green-600 via-green-500 to-green-700 [text-shadow:0_0_rgba(0,0,0,0.1)]">
        LOADING, PLEASE WAIT
      </div>
    </div>
  );
};

export default loading;
